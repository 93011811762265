import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import PropTypes from 'prop-types'

import useAuth from '@hooks/useAuth'
import { PATHS } from '../../../Routes'

PrivateRoute.propTypes = {
  children: PropTypes.element
}

function PrivateRoute ({ children, ...props }) {
  const [{ isAuthenticated }] = useAuth()

  return (
    <Route {...props}>
      {isAuthenticated ? children : <Redirect to={{ pathname: PATHS.INIT }} />}
    </Route>
  )
}

export default PrivateRoute
