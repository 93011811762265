export default function reducer (state, { action, values }) {
  let newState = { ...state }

  if (action === 'update') {
    newState = Object.assign({}, state, values)
  }

  if (action === 'reset') {
    newState = {
      profile: {
        active: null,
        username: null,
        image: null
      },
      customerKey: null,
      accessToken: null,
      isAuthenticated: false,
      time: null
    }
  }

  return newState
}
