import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { PATHS } from '../../Routes'

UserMenu.propTypes = {
  name: PropTypes.string.isRequired,
  setOpenSidebar: PropTypes.func.isRequired
}

export default function UserMenu ({ name, setOpenSidebar }) {
  return useMemo(
    () =>
      name && (
        <>
          <div className="dropdown user-menu">
            <button
              aria-expanded="false"
              className="btn btn-user-menu dropdown-toggle"
              data-toggle="dropdown"
              id="userMenuDropdown"
            >
              <img alt="" src="/assets/img/setting-icon.svg" />
            </button>
            <ul aria-labelledby="userMenuDropdown" className="list-submenu dropdown-menu dropdown-menu-right">
              <li>
                <Link className="dropdown-item" to={PATHS.PROFILE_PERSONAL} onClick={() => setOpenSidebar(false)}>
                  <img alt="" src="/assets/img/user-icon.svg" />
                  <span>Mis datos personales</span>
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to={PATHS.PROFILE_COMMERCIAL} onClick={() => setOpenSidebar(false)}>
                  <img alt="" src="/assets/img/edit-icon.svg"/>
                  <span>Mis datos comerciales</span>
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to={PATHS.PROFILE_PASSWORD} onClick={() => setOpenSidebar(false)}>
                  <img alt="" src="/assets/img/lock-icon.svg"/>
                  <span>Cambio de contraseña</span>
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to={PATHS.PROFILE_LOCK_CARD} onClick={() => setOpenSidebar(false)}>
                  <img alt="" src="/assets/img/credit-icon.svg"/>
                  <span>Bloqueo de tarjeta</span>
                </Link>
              </li>
            </ul>
          </div>
        </>
      ),
    [name, setOpenSidebar]
  )
}
