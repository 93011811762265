import React, { useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'

import useAuth from '@hooks/useAuth'
import useProductContext from '@hooks/useProductContext'

import _get from 'lodash/get'
import _find from 'lodash/find'
import _size from 'lodash/size'

import UserMenu from './UserMenu'
import { PATHS } from '../../Routes'

Sidebar.propTypes = {
  formattedName: PropTypes.string.isRequired,
  menuLinks: PropTypes.array.isRequired,
  openSidebar: PropTypes.bool.isRequired,
  setOpenSidebar: PropTypes.func.isRequired,
  showSuperWithdrawal: PropTypes.bool.isRequired
}

export default function Sidebar ({ menuLinks, formattedName, openSidebar, setOpenSidebar, showSuperWithdrawal }) {
  const { pathname } = useLocation()
  const [, { logout }] = useAuth()
  const [{ hasPrepaidProduct, hasCreditProduct }] = useProductContext()

  return useMemo(
    () => {
      const renderItem = ({ link, label, key, submenu = [] }) => (
        <React.Fragment key={key}>
          <li className={`sidebar-li collapsed ${link === pathname ? 'active' : ''}`}>
            <Link className="sidebar-li-item" to={link} onClick={() => setOpenSidebar(false)}>
              {label}
            </Link>
            <span
              aria-controls={`accordion${key}`}
              aria-expanded="false"
              className="collapsed"
              data-toggle="collapse"
              href={`#accordion${key}`}
              role="button"
            >
              {_size(submenu) > 0 && (
                <span className="dropdown-icon">
                  <img alt="" src="/assets/img/arrow-down.svg" />
                </span>
              )}
            </span>
          </li>
          {_size(submenu) > 0 && (
            <div className="collapse" id={`accordion${key}`}>
              <ul className="subsidebar-li">
                {submenu.map((link, key2) => (
                  <li className="sidebar-li" key={key2}>
                    <Link className="sidebar-li-item" to={link.link} onClick={() => setOpenSidebar(false)}>
                      <span>{link.label}</span>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </React.Fragment>
      )

      const renderCardsItem = (link) => {
        const submenu = _get(link, 'submenu', []).filter(link =>
          (hasPrepaidProduct && link.link === PATHS.PREPAID_CARD) ||
          (hasCreditProduct && link.link === PATHS.CREDIT_CARD)
        )
        return renderItem({ ...link, submenu })
      }

      const renderWithdrawalItem = (link) => {
        const submenu = showSuperWithdrawal ? _get(link, 'submenu', []) : []
        return renderItem({ ...link, submenu })
      }

      return (
        <div className={`container-sidebar ${openSidebar ? 'show-sidebar' : 'close-sidebar'}`}>
          <button className='overlay-sidebar' type='button' onClick={() => setOpenSidebar(false)}>
          </button>
          <div className='sidebar-nav'>
            <div className='sidebar-header'>
              <p className='welcome'>Bienvenido, {formattedName}</p>
              <div className="header-right">
                <UserMenu name={formattedName} setOpenSidebar={setOpenSidebar} />
              </div>
              {/*
              <button className="btn-user-menu" href={PATHS.HOME}>
                <img src="/assets/img/setting-icon.svg"/>
              </button>
              */}
            </div>
            <ul className='sidebar-ul'>
              {menuLinks.map((link, key) => {
                const isCardsLink = _get(link, 'link', '') === PATHS.CARDS
                const isSuperWithdrawalLink = !!_find(_get(link, 'submenu', []), { link: PATHS.SUPERWITHDRAWAL })

                if (isCardsLink) {
                  return renderCardsItem({ ...link, key })
                } else if (isSuperWithdrawalLink) {
                  return renderWithdrawalItem({ ...link, key })
                } else {
                  return renderItem({ ...link, key })
                }
              })}
            </ul>
            <div className='sidebar-footer'>
              <button className='btn-outline' onClick={() => logout()}>
                <span>Cerrar sesión</span>
              </button>
            </div>
          </div>
        </div>
      )
    },
    [openSidebar, formattedName, setOpenSidebar, menuLinks, pathname, hasPrepaidProduct, hasCreditProduct, showSuperWithdrawal, logout]
  )
}
