import React, { useMemo, useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'

import useAuth from '@hooks/useAuth'
import useProductContext from '@hooks/useProductContext'
import tricorService from '@services/tricorService'
import _capitalize from 'lodash/capitalize'
import _get from 'lodash/get'

import { creditMenuLinks, prepaidMenuLinks } from './menuLinks'
import Navbar from './Navbar'
import Sidebar from './Sidebar'
import UserMenu from './UserMenu'
import { PATHS } from '../../Routes'

// eslint-disable-next-line max-statements
export default function Header () {
  const [{ isAuthenticated, profile }, { logout }] = useAuth()
  const [{ selectedProduct, isSelectedCreditProduct, isSelectedPrepaidProduct }] = useProductContext()
  const [openSidebar, setOpenSidebar] = useState(false)
  const [showSuperWithdrawal, setShowSuperWithdrawal] = useState(false)
  const [header, setHeader] = useState(true)

  const checkSuperWithdrawal = async () => {
    const { data } = await tricorService(64, {
      origin: 'SUPERWITHDRAWAL',
      identificationNumber: _get(profile, 'id') // RUT del cliente sin puntos ni guión
    })
    setShowSuperWithdrawal(_get(data, 'feasibility', false))
  }

  const location = useLocation()
  const currentUrl = location.pathname
  useEffect(() => {
    if (isAuthenticated) {
      checkSuperWithdrawal()
    }
    if (currentUrl !== '/actualiza-clave') {
      setHeader(true)
    } else {
      setHeader(false)
    }
  // eslint-disable-next-line
  }, [isAuthenticated, currentUrl])

  const customerName = _get(selectedProduct, 'profile.name', '')
    .split(' ')
    .map(word => _capitalize(word))
    .join(' ')

  const menuLinks = useMemo(() => {
    if (isSelectedCreditProduct) {
      return creditMenuLinks
    } else if (isSelectedPrepaidProduct) {
      return prepaidMenuLinks
    } else {
      return []
    }
  }, [isSelectedCreditProduct, isSelectedPrepaidProduct])

  return useMemo(
    () => (
      <div className="header">
        <div className="container-fluid">
          <div className="container-header">
            {header
              ? (
                <div className="logo">
              <Link to={PATHS.HOME}>
                <img alt="tricard" src="/assets/img/tricot-visa.svg" />
              </Link>
            </div>
                )
              : (
                <div className="logo">
                <img alt="tricard" src="/assets/img/tricot-visa.svg" />
            </div>
                )}
                {/* <p>v.02092024 branch: khipu</p> */}
            <div className="user">
              {customerName && (
                <>
                  <p>Bienvenido, {customerName}</p>
                  <div className="header-right">
                    <UserMenu name={customerName} setOpenSidebar={setOpenSidebar} />
                  </div>
                </>
              )}
              {(isAuthenticated) && (
                <button className="icon-logout" onClick={() => logout()}>
                  <img alt="Cerrar sesion" src="/assets/img/logout-icon.svg" />
                </button>
              )}
            </div>
          </div>
          <Navbar
            customerName={customerName}
            menuLinks={menuLinks}
            openSidebar={openSidebar}
            setOpenSidebar={setOpenSidebar}
            showSuperWithdrawal={showSuperWithdrawal}
          />
          <Sidebar
            formattedName={customerName}
            menuLinks={menuLinks}
            openSidebar={openSidebar}
            setOpenSidebar={setOpenSidebar}
            showSuperWithdrawal={showSuperWithdrawal}
          />
        </div>
      </div>
    ),
    [customerName, isAuthenticated, menuLinks, openSidebar, showSuperWithdrawal, logout]
  )
}
