import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import _size from 'lodash/size'

SubNavbar.propTypes = {
  submenu: PropTypes.array
}

export default function SubNavbar ({ submenu = [] }) {
  return (
    _size(submenu) > 0 && (
      <>
        <span className="caret"></span>
        <div className="hover-submenu"></div>
        <ul className="sub-nav">
          {submenu.map((link, key) => (
            <li key={key}>
              <Link to={link.link}>{link.label}</Link>
            </li>
          ))}
        </ul>
      </>
    )
  )
}
