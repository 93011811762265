import React from 'react'

import PropTypes from 'prop-types'

import Container from './Container'
import Footer from './Footer'
import Header from './Header'
import './css/style.scss'

DefaultLayout.propTypes = {
  children: PropTypes.any
}

export default function DefaultLayout ({ children }) {
  return (
    <>
      <Header />
      <Container>{children}</Container>
      <Footer />
    </>
  )
}
