import axios from 'axios'
import { configure } from 'axios-hooks'

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL
axios.defaults.headers.common['Content-Type'] = 'application/json'

const axiosInstance = axios.create()
configure({ axios: axiosInstance })

export default axiosInstance
