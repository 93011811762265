export const PRODUCT_TYPES = {
  CREDIT: 'credit',
  PREPAID: 'prepaid'
}

export const PRODUCT_TYPE_CODES = {
  VISA_NATIONAL: 1,
  VISA_INTERNATIONAL: 2,
  PREPAID: 3
}
