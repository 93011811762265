import { useContext } from 'react'

import { ProductContext } from './ProductProvider'

export function useProductContext () {
  const context = useContext(ProductContext)
  if (context === undefined) {
    throw new Error('useProductContext must be within a ProductProvider')
  }
  return context
}

export default useProductContext
