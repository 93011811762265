import { PATHS } from '../../Routes'

export const creditMenuLinks = [
  {
    label: 'Tarjetas',
    link: PATHS.CARDS,
    submenu: [
      {
        label: 'Tricot Visa',
        link: PATHS.CREDIT_CARD
      },
      {
        label: 'Tricot Prepago',
        link: PATHS.PREPAID_CARD
      }
    ]
  },
  /*
  {
    label: 'Mi Cuenta',
    link: PATHS.ACCOUNT,
    submenu: [
      {
        label: 'Últimos pagos',
        link: PATHS.LAST_PAYMENTS
      }
    ]
  },
  */
  {
    label: 'Solicitar avance',
    link: PATHS.WITHDRAWAL,
    submenu: [
      {
        label: 'Solicitar súper avance',
        link: PATHS.SUPERWITHDRAWAL
      }
    ]
  },
  {
    label: 'Reordena tu deuda',
    link: PATHS.RENEGOTIATION_DEBT
  },
  {
    label: 'Mis beneficios',
    link: PATHS.BENEFITS
  },
  {
    label: 'Pagar en línea',
    link: PATHS.PAYMENT
  },
  {
    label: 'Estados de cuenta',
    link: PATHS.ACCOUNT_STATES
  },
  {
    label: 'Mis documentos',
    link: PATHS.DOCUMENTS,
    submenu: [
      {
        label: 'Boletas',
        link: PATHS.DOCUMENTS_TICKETS
      },
      {
        label: 'Estados de cuenta',
        link: PATHS.DOCUMENTS_ACCOUNT_STATES
      },
      {
        label: 'Contratos',
        link: PATHS.DOCUMENTS_CONTRACTS
      },
      {
        label: 'Seguros',
        link: PATHS.DOCUMENTS_INSURANCES
      }
    ]
  },
  {
    label: 'Seguros',
    link: PATHS.INSURANCES
  }
]

export const prepaidMenuLinks = [
  {
    label: 'Tarjetas',
    link: PATHS.CARDS,
    submenu: [
      {
        label: 'Tricot Visa',
        link: PATHS.CREDIT_CARD
      },
      {
        label: 'Tricot Prepago',
        link: PATHS.PREPAID_CARD
      }
    ]
  },
  {
    label: 'Movimientos',
    link: PATHS.PREPAID_MOVEMENTS
  },
  {
    label: 'Cartolas',
    link: PATHS.CARTOLAS
  }
]
