import axiosInstance from '@services/axiosInstance'

const getService = {
  5: ({ id, values }) => axiosInstance.patch(`/v1/authentications/${id}`, values),
  8: ({ id, entityCode, productCode }) => {
    const params = { entityCode, productCode }
    return axiosInstance.get(`/v1/customers/${id}/periods`, { params })
  },
  6: ({ region }) => axiosInstance.get(`/v1/communes/${region}`),
  10: ({ id, folio, date }) =>
    axiosInstance.get(`/v1/customers/${id}/documents/${folio}?date=${date}`),
  11: ({ id, page, entityCode, productCode }) => {
    const params = { page, entityCode, productCode }
    return axiosInstance.get(`/v1/customers/${id}/payments`, { params })
  },
  16: ({ id, entityCode, productCode }) => {
    const params = { entityCode, productCode }
    return axiosInstance.get(`/v1/customers/${id}`, { params })
  },
  18: ({ id, calendarPeriod, entityCode, productCode }) => {
    const params = { entityCode, productCode }
    return axiosInstance.get(
      `/v1/customers/${id}/movements/${calendarPeriod}`,
      { params }
    )
  },
  19: ({ id, entityCode, productCode }) => {
    const params = { entityCode, productCode }
    return axiosInstance.get(`/v1/customers/${id}/noninvoicedmovements`, {
      params
    })
  },
  22: () => axiosInstance.get('/v1/accounttypes'),
  23: () => axiosInstance.get('/v1/banks'),
  24: () => axiosInstance.get('/v1/paymentmethods'),
  38: ({ id, entityCode, productCode }) => {
    const params = { identificationNumber: id, entityCode, productCode }
    return axiosInstance.get('/v1/negativerecords', { params })
  },
  39: (values) => axiosInstance.post('/v1/negativerecords', values),
  48: (values) => axiosInstance.put('/v1/creditcards/status', values),
  52: (values) => axiosInstance.post('/v1/validations', values),
  53: ({ id, values }) =>
    axiosInstance.put(`/v1/customers/${id}/contactabilitydata`, values),
  54: ({ id, values }) =>
    axiosInstance.put(`/v1/customers/${id}/commercialdata`, values),
  55: ({ id, values }) =>
    axiosInstance.put(`/v1/customers/${id}/personaldata`, values),
  56: ({ id }) =>
    axiosInstance.get(`/v1/insurances?identificationNumber=${id}`),
  57: ({ id, values }) =>
    axiosInstance.put(`/v1/customers/${id}/insurancedata`, values),
  58: ({ id, page }) =>
    axiosInstance.get(`/v1/documents?identificationNumber=${id}&page=${page}`),
  59: ({ folio }) => axiosInstance.get(`/v1/documents/${folio}`),
  60: (values) => axiosInstance.post('/v1/documents/emailing', values),
  61: (values) => axiosInstance.post('/v1/withdrawals/beginning', values),
  62: (values) => axiosInstance.post('/v1/withdrawals/simulation', values),
  63: (values) => axiosInstance.post('/v1/withdrawals/confirmation', values),
  64: (values) => axiosInstance.post('/v1/withdrawals/feasibility', values),
  65: ({ id, processId }) =>
    axiosInstance.get(`/v1/withdrawals/${id}/processes/${processId}`),
  66: (values) =>
    axiosInstance.post('/v1/withdrawals/confirmation/retry', values),
  67: (values) => axiosInstance.patch('v1/authentications', values),
  68: ({ id }) => axiosInstance.get(`/v1/customers/${id}/products`),
  79: (values) => axiosInstance.post('/v1/renegotiations/beginning', values),
  80: (values) => axiosInstance.post('/v1/renegotiations/simulation', values),
  81: (values) => axiosInstance.post('/v1/renegotiations/payment', values),
  82: (values) => axiosInstance.post('/v1/renegotiations/confirmation', values),
  83: ({ processId, id }) =>
    axiosInstance.post('/v1/withdrawals/validationotp', {
      processId,
      identificationNumber: id
    }),
  84: ({ otpCode, processId, id }) =>
    axiosInstance.post('/v1/withdrawals/validationotp/attempt', {
      otpCode,
      processId,
      identificationNumber: id
    })
}

export default function tricorService (number, values = {}) {
  try {
    return getService[parseInt(number)](values)
  } catch (err) {
    return Promise.reject(err)
  }
}
