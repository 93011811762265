export default function Loading () {
  return (
    <div className="m-5">
      <div className="d-flex justify-content-center align-items-center">
        <p aria-label="cargando" className="text m-0 mr-3">Cargando</p>
        <div className="spinner-grow text-danger" role="status"></div>
      </div>
    </div>
  )
}
