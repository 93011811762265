import { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import Cookies from 'js-cookie'

import axiosInstance from '@services/axiosInstance'
import tricorService from '@services/tricorService'

import _get from 'lodash/get'
import _has from 'lodash/has'
import _trimStart from 'lodash/trimStart'

import { useAuthContext } from './context'
import { PATHS } from '../../Routes'

let interceptorEnabled = false

const ERROR_MESSAGES = {
  401: 'Lo sentimos! Hemos tenido un error al tratar de procesar tu solicitud, por favor inténtalo nuevamente más tarde.',
  403: 'Lo sentimos! Hemos tenido un error al tratar de procesar tu solicitud, por favor inténtalo nuevamente más tarde.',
  500: 'Lo sentimos! Hemos tenido un error al tratar de procesar tu solicitud, por favor inténtalo nuevamente más tarde.'
}

const ERROR_CODES_TO_EXIT = [401, 403]

export default function useAuth () {
  const [state, dispatch] = useAuthContext()
  const { push } = useHistory()
  const { addToast } = useToasts()

  const cleanUp = useCallback(() => {
    delete axiosInstance.defaults.headers.common.Authorization
    window.localStorage.clear()
    window.sessionStorage.clear()
    Cookies.remove('CustomerId')
    Cookies.remove('CustomerKey')
    Cookies.remove('AccessToken')
    dispatch({ action: 'reset' })
    push(PATHS.LOGOUT)
  }, [push, dispatch])

  const errorCallback = useCallback(
    err => {
      if (_has(err, 'response.status')) {
        if (_get(ERROR_MESSAGES, err.response.status, false)) {
          addToast(ERROR_MESSAGES[err.response.status], { appearance: 'error' })
        }
        if (ERROR_CODES_TO_EXIT.includes(err.response.status)) {
          cleanUp()
        }
      }
      // console.error(err)
      return Promise.reject(err)
    },
    [addToast, cleanUp]
  )

  const actions = useMemo(
    () => ({
      setSession: async ({ accessToken, customerKey, customerId }) => {
        await dispatch({
          action: 'update',
          values: {
            accessToken,
            customerKey,
            profile: {
              id: _trimStart(customerId, '0'),
              executiveCode: 1234, // Código ejecutivo
              branchCode: 711 // Código sucursal
            },
            isAuthenticated: accessToken && customerKey && customerId
          }
        })
      },
      logout: async () => {
        try {
          await tricorService(67, { identificationNumber: _get(state, 'profile.id') })
          cleanUp()
        } catch (error) {
          console.error('Error', error)
        }
      }
    }),
    [state, dispatch, cleanUp]
  )

  const interceptor = useCallback(() => {
    axiosInstance.interceptors.response.use(
      res => res,
      err => errorCallback(err)
    )
  }, [errorCallback])

  if (!interceptorEnabled) {
    interceptor()
    interceptorEnabled = true
  }

  return [state, actions]
}
