import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { PATHS } from '../../Routes'

const publicSiteUrl = process.env.REACT_APP_PUBLIC_SITE_URL

export default function Footer () {
  return useMemo(
    () => (
      <footer>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md">
              <ul className="list-footer">
                <h3 className="title-footer">Tarjeta</h3>
                <li>
                  <Link to={PATHS.BENEFITS}>Beneficios</Link>
                </li>
                <li>
                  <a
                    href={`${publicSiteUrl}/index.php/tarjeta`}
                    rel="noreferrer"
                    target="_blank"
                  >
                    Requisitos
                  </a>
                </li>
                <li>
                  <a
                    href={`${publicSiteUrl}/tasas-y-comisiones`}
                    rel="noreferrer"
                    target="_blank"
                  >
                    Tasas y Comisiones
                  </a>
                </li>
                <li>
                  <a
                    href={`${publicSiteUrl}/index.php/contratos`}
                    rel="noreferrer"
                    target="_blank"
                  >
                    Contratos
                  </a>
                </li>
                <li>
                  <a
                    href={`${publicSiteUrl}/index.php/buscador-de-sucursales`}
                    rel="noreferrer"
                    target="_blank"
                  >
                    Sucursales
                  </a>
                </li>
                <li>
                  <Link to={PATHS.PAYMENT}>Paga tu cuenta</Link>
                </li>
              </ul>
            </div>
            <div className="col-md">
              <ul className="list-footer">
                <h3 className="title-footer">Horarios de atención</h3>
                <li>
                  <a
                    href={`${publicSiteUrl}/quienes-somos`}
                    rel="noreferrer"
                    target="_blank"
                  >
                    Quienes Somos
                  </a>
                </li>
                <li>
                  <a
                    href={`${publicSiteUrl}/preguntas-frecuentes`}
                    rel="noreferrer"
                    target="_blank"
                  >
                    Preguntas Frecuentes
                  </a>
                </li>
                <li>
                  <a
                    href={`${publicSiteUrl}/bloquea-tu-tarjeta`}
                    rel="noreferrer"
                    target="_blank"
                  >
                    Seguridad y Bloqueo
                  </a>
                </li>
                <li>
                  <a
                    href={`${publicSiteUrl}/informacion-legal-y-financiera`}
                    rel="noreferrer"
                    target="_blank"
                  >
                    Información legal y Financiera
                  </a>
                </li>
                <li>
                  <a
                    href={`${publicSiteUrl}/estados-financieros`}
                    rel="noreferrer"
                    target="_blank"
                  >
                    Estados Financieros
                  </a>
                </li>
                {/*
                <li>
                  <a href="">
                    Boleta Electrónica
                  </a>
                </li>
                */}
              </ul>
            </div>
            <div className="col-md">
              <ul className="list-footer">
                <h3 className="title-footer">Contacto</h3>
                <li>
                  <p>Servicio al Cliente: 600 450 5500</p>
                </li>
                <li>
                  <p>Lunes a Domingo de 9:00 a 21:00 hrs</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="subfooter">
          <p>© Tricot. Todos los derechos reservados.</p>
          <p>Infórmese sobre las entidades autorizadas para emitir Tarjetas de Pago en el país, quienes se encuentran inscritas en los Registros de Emisores de Tarjetas que lleva la CMF, en <a
            href="https://www.cmfchile.cl"
            rel="noreferrer"
            target="_blank"
          >
               www.cmfchile.cl.
          </a></p>
        </div>
      </footer>
    ),
    []
  )
}
