import React from 'react'
import PropTypes from 'prop-types'

Notification.propTypes = {
  appearance: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
}

const imageKind = {
  success: '/assets/img/check-circle.svg',
  error: '/assets/img/error-circle.svg',
  warning: '/assets/img/warning-icon.svg',
  info: '/assets/img/info-circle.svg'
}

export default function Notification ({ appearance, children }) {
  return (
    <div className={`alert alert-${appearance} notification`}>
      <img alt="icono" className="icon" src={imageKind[String(appearance)]} />
      <p>{children}</p>
    </div>
  )
}
