import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import formatRUT from '@utils/formatRut'

FormikInput.propTypes = {
  formik: PropTypes.object,
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.oneOf(['text', 'password']),
  className: PropTypes.string,
  placeholder: PropTypes.string,
  maxLength: PropTypes.string,
  disabled: PropTypes.bool,
  pattern: PropTypes.any,
  async: PropTypes.bool,
  kind: PropTypes.oneOf(['default', 'rut', 'number', 'money']),
  onValueChange: PropTypes.func // Añadido aquí
}

const inputKind = {
  default: value => value,
  rut: value => (!value ? '' : formatRUT(value)),
  number: value => (!value ? '' : String(value).replace(/\D/g, '')),
  money: value =>
    !value
      ? '$0'
      : new Intl.NumberFormat('es-CL', {
        style: 'currency',
        currency: 'CLP'
      }).format(parseInt(String(value).match(/\d+/g)))
}

const outputKind = {
  default: value => value,
  rut: value => String(value).split('.').join('')
    .split('-')
    .join(''),
  number: value => String(value).replace(/\D/g, ''),
  money: value => String(value).replace(/\D/g, '')
}

export default function FormikInput ({
  formik,
  id,
  name,
  label,
  type = 'text',
  kind = 'default',
  className = '',
  placeholder = '',
  maxLength = '',
  disabled = false,
  async = false,
  onValueChange // Añadido aquí
}) {
  const value = formik.values[String(name)] ?? ''
  const parsedValue = inputKind[String(kind)](value)
  const error = formik.errors[String(name)]
  const isTouched = formik.touched[String(name)]

  const onBlur = formik.handleBlur

  const onChange = useCallback(
    e => {
      const newValue = outputKind[String(kind)](e.target.value)
      formik.setFieldValue(name, newValue)
      if (onValueChange) {
        onValueChange(newValue)
      }
    },
    [formik, kind, name, onValueChange]
  )

  return useMemo(
    () => (
      <div className="form-group">
        {label && <label htmlFor={id}>{label}</label>}
        <input
          className={`form-control ${className}`}
          disabled={disabled}
          id={id}
          maxLength={maxLength}
          name={name}
          placeholder={placeholder}
          type={type}
          value={parsedValue}
          onBlur={onBlur}
          onChange={onChange}
        />
        {async && error && <div className="invalid-feedback d-inline-block">{error}</div>}
        {!async && error && isTouched && <div className="invalid-feedback d-inline-block">{error}</div>}
      </div>
    ),
    [id, name, label, parsedValue, type, onBlur, onChange, className, placeholder, maxLength, disabled, error, async, isTouched]
  )
}
