import React, { useEffect, useState } from 'react'

import useAuth from '@hooks/useAuth'
import axiosInstance from '@services/axiosInstance'
import PropTypes from 'prop-types'

import _map from 'lodash/map'
import _uniqBy from 'lodash/uniqBy'
import _get from 'lodash/get'
import _sortBy from 'lodash/sortBy'
import _reverse from 'lodash/reverse'

import moment from 'moment'

BenefitProvider.propTypes = {
  children: PropTypes.element.isRequired
}

const BENEFITS_URL = `${process.env.REACT_APP_PUBLIC_SITE_URL}/wp-json/tricard_api/v1/benefits`

export const BenefitContext = React.createContext()

export default function BenefitProvider ({ children }) {
  const [{ isAuthenticated }] = useAuth()
  const [loading, setLoading] = useState(true)
  const [benefits, setBenefits] = useState([])
  const [categories, setCategories] = useState([])

  useEffect(() => {
    const getBenefits = async () => {
      const { data } = await axiosInstance.request({
        url: BENEFITS_URL,
        transformRequest: (_data, headers) => {
          delete headers.common['customer-key']
        }
      })
      const categories = _uniqBy(_map(data, d => ({
        key: _get(d, 'benefit_id', 0),
        label: _get(d, 'benefit_name', '')
      })), 'key')
      const benefits = _reverse(_sortBy(
        _uniqBy(data, 'post_id'),
        (b) => moment(_get(b, 'fecha_modificada')).toDate()
      ))
      setCategories(categories)
      setBenefits(benefits)
      setLoading(false)
    }

    if (isAuthenticated) {
      getBenefits()
    }
  }, [isAuthenticated])

  return (
    <BenefitContext.Provider value={{ loading, benefits, categories }}>
      {children}
    </BenefitContext.Provider>
  )
}
