/* eslint-disable no-unused-vars */
import React, { useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'

import useProductContext from '@hooks/useProductContext'
import _get from 'lodash/get'
import _find from 'lodash/find'

import SubNavbar from './SubNavbar'
import UserMenu from './UserMenu'
import { PATHS } from '../../Routes'

Navbar.propTypes = {
  customerName: PropTypes.string.isRequired,
  menuLinks: PropTypes.array.isRequired,
  openSidebar: PropTypes.bool.isRequired,
  setOpenSidebar: PropTypes.func.isRequired,
  showSuperWithdrawal: PropTypes.bool.isRequired
}

export default function Navbar ({ menuLinks, customerName, openSidebar, setOpenSidebar, showSuperWithdrawal }) {
  const { pathname } = useLocation()
  const [{ hasPrepaidProduct, hasCreditProduct }] = useProductContext()

  return useMemo(
    () => {
      const renderNavItem = (link, key) => (
        <li className={link.link === pathname ? 'nav-item active' : 'nav-item'} key={key}>
          <Link className="nav-link" to={link.link}>
            {link.label}
          </Link>
          <SubNavbar submenu={_get(link, 'submenu', [])} />
        </li>
      )

      const renderCardsNavItem = (link, key) => {
        const submenu = _get(link, 'submenu', []).filter(link =>
          (hasPrepaidProduct && link.link === PATHS.PREPAID_CARD) ||
          (hasCreditProduct && link.link === PATHS.CREDIT_CARD)
        )

        return (
          <li className={[link.link, PATHS.PREPAID_CARD, PATHS.CREDIT_CARD].includes(pathname) ? 'nav-item active' : 'nav-item'} key={key}>
            <Link className="nav-link" to={link.link}>
              {link.label}
            </Link>
            <SubNavbar submenu={submenu} />
          </li>
        )
      }

      const renderWithdrawalNavItem = (link, key) => (
        <li className={link.link === pathname ? 'nav-item active' : 'nav-item'} key={key}>
          <Link className="nav-link" to={link.link}>
            {link.label}
          </Link>
          {showSuperWithdrawal && <SubNavbar submenu={_get(link, 'submenu', [])} />}
        </li>
      )

      return (
        <div className="navbar">
          <nav className="nav">
            <div></div>
            <div className="logo">
              <Link to={PATHS.HOME}>
                <img alt="tricard" src="/assets/img/tricot-visa.svg" />
              </Link>
            </div>
            <div className="header-right">
              <UserMenu name={customerName} setOpenSidebar={setOpenSidebar} />
              {customerName && (
                <button className="burger-menu" onClick={() => setOpenSidebar(!openSidebar)}>
                  <span className="">
                    <img alt="menu" src="/assets/img/menu_mobile.svg" />
                  </span>
                </button>
              )}
            </div>
            <div className="nav-list">
              <ul className="list">
                {customerName && <p className="welcome">Bienvenido, {customerName}</p>}
                {menuLinks.map((link, key) => {
                  const isCardsLink = _get(link, 'link', '') === PATHS.CARDS
                  const isSuperWithdrawalLink = !!_find(_get(link, 'submenu', []), { link: PATHS.SUPERWITHDRAWAL })

                  if (isCardsLink) {
                    return renderCardsNavItem(link, key)
                  } else if (isSuperWithdrawalLink) {
                    return renderWithdrawalNavItem(link, key)
                  } else {
                    return renderNavItem(link, key)
                  }
                })}
              </ul>
            </div>
          </nav>
        </div>
      )
    },
    [customerName, setOpenSidebar, menuLinks, pathname, hasPrepaidProduct, hasCreditProduct, showSuperWithdrawal, openSidebar]
  )
}
