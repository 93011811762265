import React, { useEffect, useReducer, useMemo } from 'react'

import useAuth from '@hooks/useAuth'
import tricorService from '@services/tricorService'
import PropTypes from 'prop-types'

import productReducer, { initialState, isCreditCard, isPrepaidCard } from './productReducer'
import { PRODUCT_TYPES } from './constants'

import _get from 'lodash/get'

ProductProvider.propTypes = {
  children: PropTypes.element.isRequired
}

export const ProductContext = React.createContext()

export default function ProductProvider ({ children }) {
  const [{ isAuthenticated, profile }] = useAuth()
  const [state, dispatch] = useReducer(productReducer, initialState)

  useEffect(() => {
    const getProducts = async () => {
      const { data } = await tricorService(68, { id: _get(profile, 'id') })
      const products = await Promise.all(
        data?.products.map(async prod => {
          const { data } = await tricorService(16, {
            id: _get(profile, 'id'),
            entityCode: prod.entityCode,
            productCode: prod.productTypeCode
          })
          return {
            ...prod,
            profile: data?.customer
          }
        })
      )
      dispatch({ action: 'SET_PRODUCTS', values: products })
      dispatch({ action: 'SET_LOADING', values: false })
    }

    if (isAuthenticated) {
      getProducts()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])

  const creditProduct = useMemo(() => state.products.find(isCreditCard), [state.products])

  const prepaidProduct = useMemo(() => state.products.find(isPrepaidCard), [state.products])

  const selectedProduct = useMemo(() => {
    return state.selectedType === PRODUCT_TYPES.CREDIT
      ? creditProduct
      : state.selectedType === PRODUCT_TYPES.PREPAID
        ? prepaidProduct
        : null
  }, [state.selectedType, creditProduct, prepaidProduct])

  const updateProduct = (product) => dispatch({ action: 'UPDATE_PRODUCT', values: product })

  const selectCreditProduct = () => dispatch({ action: 'SELECT_CREDIT_PRODUCT' })

  const selectPrepaidProduct = () => dispatch({ action: 'SELECT_PREPAID_PRODUCT' })

  return (
    <ProductContext.Provider value={[
      { ...state, selectedProduct, creditProduct, prepaidProduct }, // [0]: state
      { updateProduct, selectCreditProduct, selectPrepaidProduct } // [1]: dispatch functions
    ]}>
      {children}
    </ProductContext.Provider>
  )
}
