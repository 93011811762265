import React, { lazy, useEffect } from 'react'
import { Switch, Route, useLocation, useHistory } from 'react-router-dom'
import { useIdleTimer } from 'react-idle-timer'

import useAuth from '@hooks/useAuth'
import useProductContext from '@hooks/useProductContext'
import PrivateRoute from '@components/Auth/PrivateRoute'
import Loading from '@components/Loading'
import Login from '@components/Login'

const Home = lazy(() => import('./views/Home'))
const PrepaidHome = lazy(() => import('./views/PrepaidHome'))
const CreditHome = lazy(() => import('./views/Account'))
const AccountStatus = lazy(() => import('./views/Account/AccountStatus'))
const PrepaidAccountStatus = lazy(() => import('./views/PrepaidAccountStatus'))
const PrepaidMovements = lazy(() => import('./views/PrepaidMovements'))
const LastPayments = lazy(() => import('./views/Account/LastPayments'))
const Withdrawal = lazy(() => import('./views/Withdrawal'))
const SuperWithdrawal = lazy(() => import('./views/Withdrawal/SuperWithdrawal'))
const Profile = lazy(() => import('./views/Profile'))
const Debt = lazy(() => import('./views/Debt'))
const Docs = lazy(() => import('./views/Docs'))
const Insurance = lazy(() => import('./views/Insurance'))
const Benefits = lazy(() => import('./views/Benefits'))
const Payment = lazy(() => import('./views/Payment'))
//  se linkea archivo de actualizacion de contraseña
const MustUpdatePassword = lazy(() => import('./views/UpdatePassword'))

const isDevEnvironment = process.env.NODE_ENV === 'development'
const idleTimeoutMinutes = process.env.REACT_APP_IDLE_TIMEOUT_MINUTES || 5
const gaPageTitle = process.env.REACT_APP_GOOGLE_ANALYTICS_PAGE_TITLE || 'Tricard'

export const PATHS = {
  INIT: '/inicio',
  LOGOUT: '/salir',
  AUTH: '/auth',
  HOME: '/tarjetas',
  CARDS: '/tarjetas',
  CREDIT_CARD: '/tarjeta-visa',
  PREPAID_CARD: '/tarjeta-prepago',
  PREPAID_MOVEMENTS: '/movimientos',
  ACCOUNT: '/mi-cuenta',
  ACCOUNT_STATES: '/estados-de-cuenta',
  CARTOLAS: '/cartolas',
  LAST_PAYMENTS: '/ultimos-pagos',
  WITHDRAWAL: '/avance',
  SUPERWITHDRAWAL: '/super-avance',
  SUPERWITHDRAWAL_SIGNED: '/admin/super-avance/:processId',
  RENEGOTIATION_DEBT: '/reordena-tu-deuda',
  PAYMENT: '/pagar',
  DOCUMENTS: '/documentos',
  DOCUMENTS_TICKETS: '/documentos/boletas',
  DOCUMENTS_ACCOUNT_STATES: '/documentos/estados-de-cuenta',
  DOCUMENTS_CONTRACTS: '/documentos/contratos',
  DOCUMENTS_INSURANCES: '/documentos/seguros',
  PROFILE: '/perfil',
  PROFILE_PERSONAL: '/perfil/personal',
  PROFILE_COMMERCIAL: '/perfil/comercial',
  PROFILE_PASSWORD: '/perfil/clave',
  PROFILE_LOCK_CARD: '/perfil/bloqueo-tarjeta',
  BENEFITS: '/beneficios',
  INSURANCES: '/seguros',
  //  crea pagina para actualizar clave
  MUSTUPDATEPASSWORD: '/actualiza-clave'
}

function Routes () {
  const history = useHistory()
  const { pathname } = useLocation()
  const [, { logout }] = useAuth()
  const { loading: loadingProducts } = useProductContext()

  // timeout formula = miliseconds * seconds * minutes
  useIdleTimer({
    timeout: 1000 * 60 * idleTimeoutMinutes,
    onIdle: () => logout(),
    debounce: 500
  })

  useEffect(() => {
    if (window.__ga4React__) {
      history.listen(location => {
        const isTrackableLocation = ![PATHS.INIT, PATHS.LOGOUT, PATHS.AUTH].includes(location.pathname)

        if (isTrackableLocation) {
          window.__ga4React__.gtag('event', 'page_view', {
            page_title: `${gaPageTitle} - Sección ${location.pathname}`,
            page_location: location.pathname,
            page_path: location.pathname
          })
        }
      })
    }
  }, [history])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  const redirectToPublicSite = () => {
    window.location.href = process.env.REACT_APP_PUBLIC_SITE_URL
    return null
  }

  return (
    <Switch>
      {/* Session routes */}
      {isDevEnvironment ? <Route exact component={Login} path="/" /> : null}
      <Route exact component={redirectToPublicSite} path={[PATHS.INIT, PATHS.LOGOUT]} />
      <Route exact component={Loading} path={PATHS.AUTH} />
      {/* Tricard pages routes */}
      {loadingProducts ? <Route component={Loading} path="*" /> : null}
      <PrivateRoute exact path={PATHS.CARDS}>
        <Home />
      </PrivateRoute>
      <PrivateRoute exact path={PATHS.CREDIT_CARD}>
        <CreditHome />
      </PrivateRoute>
      <PrivateRoute exact path={PATHS.PREPAID_CARD}>
        <PrepaidHome />
      </PrivateRoute>
      <PrivateRoute exact path={PATHS.PREPAID_MOVEMENTS}>
        <PrepaidMovements />
      </PrivateRoute>
      <PrivateRoute exact path={PATHS.ACCOUNT}>
        <CreditHome />
      </PrivateRoute>
      <PrivateRoute path={PATHS.PROFILE}>
        <Profile />
      </PrivateRoute>
      <PrivateRoute exact path={PATHS.ACCOUNT_STATES}>
        <AccountStatus />
      </PrivateRoute>
      <PrivateRoute exact path={PATHS.CARTOLAS}>
        <PrepaidAccountStatus />
      </PrivateRoute>
      <PrivateRoute exact path={PATHS.LAST_PAYMENTS}>
        <LastPayments />
      </PrivateRoute>
      <PrivateRoute exact path={PATHS.WITHDRAWAL}>
        <Withdrawal />
      </PrivateRoute>
      <PrivateRoute exact path={PATHS.SUPERWITHDRAWAL}>
        <SuperWithdrawal />
      </PrivateRoute>
      <PrivateRoute exact path={PATHS.SUPERWITHDRAWAL_SIGNED}>
        <SuperWithdrawal />
      </PrivateRoute>
      <PrivateRoute exact path={PATHS.RENEGOTIATION_DEBT}>
        <Debt />
      </PrivateRoute>
      <PrivateRoute path={PATHS.DOCUMENTS}>
        <Docs />
      </PrivateRoute>
      <PrivateRoute path={PATHS.INSURANCES}>
        <Insurance />
      </PrivateRoute>
      <PrivateRoute path={PATHS.BENEFITS}>
        <Benefits />
      </PrivateRoute>
      <PrivateRoute exact path={PATHS.PAYMENT}>
        <Payment />
      </PrivateRoute>
      <PrivateRoute exact path={PATHS.MUSTUPDATEPASSWORD}>
        <MustUpdatePassword />
      </PrivateRoute>
    </Switch>
  )
}

export default Routes
