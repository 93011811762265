import React from 'react'
import PropTypes from 'prop-types'
import Modal from '@components/Modal'

const AlertForm = ({ open = false, onClose }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <div className="container">
        <div className="box-shadow modal-content bg-white">
          <div className="wrapper">
          <h4 className='title-pass'>Atención</h4>
              <p className="title-pass">Al pagar su cuota de este mes, Ud. tiene un descuento en la próxima compra
                que realice en Tricot, por haber completado el pago de su deuda</p>
            <div className='btn-action'>
            <button className="btn-active btn-outline" onClick={onClose}>
              <span>Cerrar</span>
            </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>

  )
}

AlertForm.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired
}

export default AlertForm
