import { Suspense } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { ToastProvider } from 'react-toast-notifications'

import Loading from '@components/Loading'
import Notification from '@components/Notification'
import AuthProvider from '@hooks/useAuth/AuthProvider'
import ProductProvider from '@hooks/useProductContext/ProductProvider'
import BenefitProvider from '@views/Benefits/useBenefitContext/BenefitProvider'

import DefaultLayout from './layouts/Default'
import Routes from './Routes'

function App () {
  return (
    <Router>
      <AuthProvider>
        <ToastProvider autoDismiss={true} components={{ Toast: Notification }}>
          <ProductProvider>
            <BenefitProvider>
              <DefaultLayout>
                <Suspense fallback={<Loading />}>
                  <Routes />
                </Suspense>
              </DefaultLayout>
            </BenefitProvider>
          </ProductProvider>
        </ToastProvider>
      </AuthProvider>
    </Router>
  )
}

export default App
