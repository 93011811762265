import React from 'react'
import ReactDOM from 'react-dom'

import PropTypes from 'prop-types'

import './style.scss'

Modal.propTypes = {
  children: PropTypes.element,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}

export default function Modal ({ children, className, open, onClose }) {
  const modal = open
    ? <div className={`modal-portal ${className}`} role="dialog">
      <div className="modal-portal--content container">{children}</div>
      <div className="modal-portal--backdrop" data-testid="backdrop" onClick={onClose}></div>
    </div>
    : null
  return ReactDOM.createPortal(modal, document.getElementById('modal-root'))
}
