import { PRODUCT_TYPES, PRODUCT_TYPE_CODES } from './constants'

const creditTypeCodes = [PRODUCT_TYPE_CODES.VISA_NATIONAL, PRODUCT_TYPE_CODES.VISA_INTERNATIONAL]

export const isCreditCard = ({ productTypeCode }) => creditTypeCodes.includes(productTypeCode)

export const isPrepaidCard = ({ productTypeCode }) => productTypeCode === PRODUCT_TYPE_CODES.PREPAID

export const initialState = {
  loading: true,
  hasCreditProduct: false,
  hasPrepaidProduct: false,
  isSelectedCreditProduct: false,
  isSelectedPrepaidProduct: false,
  products: [],
  selectedType: ''
}

export default function productReducer (state, { action, values }) {
  if (!state) {
    state = initialState
  }

  switch (action) {
    case 'SET_LOADING': {
      return { ...state, loading: !!values }
    }
    case 'SET_PRODUCTS': {
      const products = values instanceof Array ? values : state.products
      return {
        ...state,
        products,
        hasCreditProduct: !!(products.find(isCreditCard)),
        hasPrepaidProduct: !!(products.find(isPrepaidCard))
      }
    }
    case 'UPDATE_PRODUCT': {
      const products = state.products.map(prod =>
        prod.productTypeCode === values?.productTypeCode ? values : prod
      )
      return { ...state, products }
    }
    case 'SELECT_CREDIT_PRODUCT': {
      return {
        ...state,
        selectedType: PRODUCT_TYPES.CREDIT ?? '',
        isSelectedPrepaidProduct: false,
        isSelectedCreditProduct: true
      }
    }
    case 'SELECT_PREPAID_PRODUCT': {
      return {
        ...state,
        selectedType: PRODUCT_TYPES.PREPAID ?? '',
        isSelectedCreditProduct: false,
        isSelectedPrepaidProduct: true
      }
    }
    case 'reset': {
      return initialState
    }
    default: {
      return state
    }
  }
}
