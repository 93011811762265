import { useEffect, useState } from 'react'

import PropTypes from 'prop-types'

Recaptcha.propTypes = {
  action: PropTypes.string,
  reset: PropTypes.bool,
  sitekey: PropTypes.string,
  onReset: PropTypes.func,
  onToken: PropTypes.func
}

export default function Recaptcha ({ action = 'submit', sitekey, reset = false, onToken, onReset }) {
  const [ready, setReady] = useState(false)

  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id)

      if (!isScriptExist) {
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = url
        script.id = id
        script.onload = function () {
          if (callback) {
            callback()
          }
        }
        document.body.appendChild(script)
      }

      if (isScriptExist && callback) {
        callback()
      }
    }

    // load the script by passing the URL
    loadScriptByURL('recaptcha-key', `https://www.google.com/recaptcha/api.js?render=${sitekey}`, function () {
      setReady(true)
    })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (ready) {
      window.grecaptcha.ready(() => {
        window.grecaptcha.execute(sitekey, { action }).then(token => {
          onToken(token)
        })
      })
    }
    // eslint-disable-next-line
  }, [ready])

  useEffect(() => {
    if (ready && reset) {
      window.grecaptcha.execute(sitekey, { action }).then(token => {
        onToken(token)
      })
      onReset()
    }
    // eslint-disable-next-line
  }, [ready, reset])

  return null
}
