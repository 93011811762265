import React from 'react'
import ReactDOM from 'react-dom'
import TagManager from 'react-gtm-module'
import GA4React from 'ga-4-react'

import './index.scss'

import App from './App'

TagManager.initialize({
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID
})

const ga4react = new GA4React(process.env.REACT_APP_GOOGLE_ANALYTICS_ID)

ga4react.initialize()
  // .then(() => config.GA_TRACKING_BLOCKED = false)
  // .catch(() => config.GA_TRACKING_BLOCKED = true)
  // eslint-disable-next-line react/no-render-return-value
  .finally(() => ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  ))
